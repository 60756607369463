import { viewPost } from '@/http/posts/posts'

export const observer = new IntersectionObserver(
	(entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting && entry.target) {
				viewPost(entry.target.id)
				observer.unobserve(entry.target)
			}
		})
	},
	{ threshold: 0 }
)
