const authPlugin = {
	request(req, token) {
		req.headers.Authorization = `Bearer ${token}`
	},
	response() {
		const token = window.localStorage.auth_token_default
		if (token) {
			return token
		}
		return null
	}
}

export default authPlugin
